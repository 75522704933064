<template>
  <!-- 电话客服 -->
  <div class="container">
    <div class="mainbox">
      <div class="maintitle">美团授权</div>
      <div class="inputbox">
        <div v-if="isAuthed" class="inputlabel">已完成授权 </div>
        <div v-else class="submit" @click="goMtAuth">前往授权</div>
      </div>
    </div>
  </div>
</template>

<script>
import { meituansession } from "@/api/api";

let redirectUrl = window.location.href.split("[?]")[0];
redirectUrl = redirectUrl + '?1=1';
//const redirect_url = 'http://localhost:81/admin/#/pages/mt-auth/mt-auth';
//console.log("redirectUrl: ", redirectUrl);

export default {
  data() {
    return {
      isAuthed: false,
    }
  },
  async created() {
    const authCode = this.$route.query.auth_code;
    const res = await meituansession({ authCode, redirectUrl });
    console.log('res', res);
    this.isAuthed = res.data.result !== null;
    console.debug("is authed: ", this.isAuthed);
  },
  methods: {
    goMtAuth() {
      const href =
        `https://e.dianping.com/dz-open/merchant/auth?app_key=f1d7830926e59516&redirect_url=${encodeURIComponent(redirectUrl)}&state=teststate&scope=[%22tuangou%22]`;
      window.open(href, '_blank');
      /* uni.navigateTo({
        url: href
      }); */
    }

  }
}
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding: 20px;

  .maintitle {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
  }

  .inputbox {
    display: flex;

    .inputlabel {
      margin-right: 20px;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 36px;
    }
  }

  .submit {
    width: 92px;
    height: 36px;
    background: #0073ff;
    opacity: 1;
    margin-top: 24px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: 36px;
    margin-left: 75px;
    border-radius: 5px;
    cursor: pointer;
  }
}

::v-deep .el-input {
  width: 320px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}
</style>
